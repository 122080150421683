@use "../../../../../styles/variables.module" as *;
@use "../../../../../styles/mixins";

// Sizes
$spotlightTileSize: 290px;
$tile-spacing: 400px;

// Base Timings (Shortened)
$rotate-duration: 0.5s;
$slide-duration: 0.8s;
$cascade-base-duration: 0.8s; // Reduced from 1.5s
$fanout-rotate-duration: 0.8s;

// Easing
$animation-ease: ease;
$animation-ease-in-out: ease-in-out;

// Delays and offsets (Shortened)
$base-index-delay: 0.1s; // Reduced from 0.2s
$rotate-to-slide-delay: 0.3s; // Reduced from 0.5s
$collapse-rotate-start-delay: 0.6s; // Reduced from 0.8s
$min-top-offset: 1px;


// Keyframes

// Rotate tiles to center alignment
@keyframes rotateToCenter {
  0% {
    transform: translateX(-50%) translate(var(--tile-fanned-offset,0), 0) rotate(var(--tile-fanned-rotation,0deg));
  }
  100% {
    transform: translateX(-50%) translate(0,0) rotate(0deg);
  }
}

// Slide tiles down to form a column
@keyframes slideDownFromCenter {
  0% { transform: translateX(-50%) translate(0,0); }
  100% { transform: translateX(-50%) translate(0, calc(#{$tile-spacing} * var(--animation-index))); }
}

// Cascade tiles up so that they all end behind the top tile at $min-top-offset
// We rely on staggered delays. The bottom tile starts first, each subsequent tile starts later.
// All end at the same position: translateX(-50%), translateY($min-top-offset).
@keyframes cascadeUp {
  0% {
    transform: translateX(-50%) translateY(calc(#{$tile-spacing} * var(--animation-index)));
  }
  100% {
    transform: translateX(-50%) translateY($min-top-offset);
  }
}

// Gradual rotate from center to fanned state with intermediate steps
// 0%: Tiles are stacked at $min-top-offset (all behind top tile)
// 30%: Move horizontally but no rotation yet
// 60%: Start rotation partially
// 100%: Fully rotated and positioned
@keyframes rotateFromCenter {
  0% {
    transform: translateX(-50%) translateY($min-top-offset) rotate(0deg);
  }
  30% {
    transform: translateX(-50%) translateY($min-top-offset) translate(var(--tile-fanned-offset,0), 0) rotate(0deg);
  }
  60% {
    transform: translateX(-50%) translateY(0)
    translate(var(--tile-fanned-offset,0), 0)
    rotate(var(--tile-fanned-rotation,0deg));
  }
  100% {
    transform: translateX(-50%) translateY(0)
    translate(var(--tile-fanned-offset,0), 0)
    rotate(var(--tile-fanned-rotation,0deg));
  }
}

/* Container */
.tilesContainer {
  display: grid;
  grid-template-columns: repeat(4, $spotlightTileSize);
  @media(max-width: $break-point-lg) {
    grid-template-columns: repeat(2, $spotlightTileSize);
  }
  justify-content: center;
  gap: 32px;
  width: 100%;
  padding: 0 5vw;
  transition: all 0.6s $animation-ease;

  @media (max-width: $break-point-md) {
    display: block;
    position: relative;
    width: 100%;
    height: calc(#{$spotlightTileSize} + 100px);

    &Expanded {
      position: relative;
      height: calc((var(--total-tiles) * #{$tile-spacing}));
    }

    &.transitioningOut {
      height: calc((var(--total-tiles) * #{$tile-spacing}));
    }

    /* Expanding (stacked → column) */
    &.transitioningOut .tile {
      // Rotate all tiles to center. Optional per-tile delay if desired:
      animation: rotateToCenter #{$rotate-duration} $animation-ease forwards;
    }

    &.transitioningOut .tile:not([data-index="0"]) {
      // After rotate, slide down
      animation:
              rotateToCenter #{$rotate-duration} $animation-ease 0s forwards,
              slideDownFromCenter #{$slide-duration} $animation-ease #{$rotate-to-slide-delay} forwards;
    }

    /* Collapsing (column → stacked) */
    &.transitioningIn {
      .tile[data-index="0"] {
        // Top tile stays at top, only rotates after all cascade is done
        animation: rotateFromCenter #{$fanout-rotate-duration} $animation-ease-in-out forwards;
        animation-delay: calc((var(--total-tiles) * #{$cascade-base-duration}) + #{$collapse-rotate-start-delay});
      }

      .tile:not([data-index="0"]) {
        // Cascade up first, then rotate out
        // Delays ensure bottom tile starts first
        animation:
                cascadeUp #{$cascade-base-duration} $animation-ease-in-out forwards,
                rotateFromCenter #{$fanout-rotate-duration} $animation-ease-in-out forwards;

        // Delay based on position: bottom tiles start earlier
        // After cascade finishes + collapse-rotate-start-delay, start fan out rotation
        animation-delay:
                calc((var(--total-tiles) - var(--animation-index)) * #{$base-index-delay}),
                calc((var(--total-tiles) * #{$cascade-base-duration}) + #{$collapse-rotate-start-delay});
      }
    }
  }

  @media (min-width: $break-point-md) {
    &.transitioningOut .tile, &.transitioningIn .tile {
      animation: none;
    }
  }
}

/* Tiles */
.tile {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  color: gray;
  border-radius: 5px;
  background: white;
  transition: transform 0.3s ease-out, z-index 0.3s;
  box-shadow: 0px 2px 2px 2px #dcdcdc;

  &:hover {
    box-shadow: 0px 10px 10px gray;
    @media (min-width: $break-point-md){
      transform: scale(1.05);
    }

    .curationContainer {
      display: flex;
    }
  }
  @media (min-width: $break-point-md) {
    // Desktop hover behavior
    &:hover .content {
      transform: translateY(0);
    }
  }

  @media (max-width: $break-point-md) {
    position: absolute;
    top: 0;
    width: $spotlightTileSize;
    left: 50%;
    transform: translateX(-50%);

    &Expanded {
      position: absolute;
      transform: translateX(-50%) translateY(calc(#{$tile-spacing} * var(--animation-index)));
      width: 100%;
      max-width: $spotlightTileSize;
      margin: 0 auto;
      z-index: calc(var(--total-tiles) - var(--animation-index));
    }

    &:not(&Expanded) {
      &[data-active="true"] {
        --tile-fanned-offset: 0%;
        --tile-fanned-rotation: 0deg;
        z-index: 4;
        transform: translateX(-50%) translate(var(--tile-fanned-offset),0) rotate(var(--tile-fanned-rotation));
      }

      &[data-position="next"] {
        --tile-fanned-offset: -40%;
        --tile-fanned-rotation: 5deg;
        z-index: 3;
        transform: translate(var(--tile-fanned-offset),0) rotate(var(--tile-fanned-rotation));
      }

      &[data-position="after"] {
        --tile-fanned-offset: -30%;
        --tile-fanned-rotation: 10deg;
        z-index: 2;
        transform: translate(var(--tile-fanned-offset),0) rotate(var(--tile-fanned-rotation));
      }

      &[data-position="previous"] {
        --tile-fanned-offset: -60%;
        --tile-fanned-rotation: -5deg;
        z-index: 3;
        transform: translate(var(--tile-fanned-offset),0) rotate(var(--tile-fanned-rotation));
      }

      &[data-position="before"] {
        --tile-fanned-offset: -70%;
        --tile-fanned-rotation: -10deg;
        z-index: 2;
        transform: translate(var(--tile-fanned-offset),0) rotate(var(--tile-fanned-rotation));
      }
    }
  }
  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    padding: 10px;
    background-color: var(--blocks);
    color: var(--blocksTextColor);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.contentVisible .content {
  transform: translateY(0);
}

.expandButton {
  padding: 8px 16px;
  background: var(--blocks);
  color: var(--blocksTextColor);
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  font-weight: 600;

  @media (min-width: $break-point-md) {
    display: none;
  }
}

/* Other styles remain unchanged */
.imageContainer {
  height: $spotlightTileSize;
  width: $spotlightTileSize;
  border-radius: 5px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.curationContainer {
  margin-top: 5px;
  display: none;
  flex-direction: row;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 95;
  .dragHandle {
    @media (max-width: $break-point-md) {
      display: none;
    }
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $admin-blue-dark;
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-right: 5px;
    padding: 4px 6px;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }

  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  transition: transform 0.3s $animation-ease-in-out;
  padding: 10px;
  background-color: var(--blocks);
  color: var(--blocksTextColor);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bannerLayer {
  position: relative;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: var(--blocks);
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tileTitle h3 {
    text-align: center;
    margin: 0;
    font-size: 20px;
    font-weight: 650;
    color: var(--blocksTextColor);
    transition: transform 0.2s $animation-ease;
    word-break: break-word;
  }
}

.message {
  background: var(--blocks);
  color: var(--blocksTextColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  font-size: 16px;
}

.tileButton {
  min-width: 90%;
  display: inline-block;
  padding: .45rem 1.25rem;
  border-radius: 5px;
  font-size: 1.2rem;
  letter-spacing: .15rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 600;
  background: var(--elements);
  color: var(--elementsTextColorNoBlocks);
  border: 2px solid white;
  transition: transform 0.3s;
  text-align: center;
  font-weight: 700;

  &:hover {
    transform: scale(1.05);
    color: var(--elementsTextColorNoBlocks); //override the color change on hover from scaffolding
  }

  @media (max-width: $break-point-sm) {
    width: auto;
    margin: 0 auto;
  }
}

.expandButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;  // Add some spacing above the button
}


