@use "../../../../../styles/variables.module" as *;
@use "../../../../../styles/mixins";
@use "../../styles/TerraceDescriptionStyles.module";

.accordionBlockWrapper {
  background: linear-gradient(90deg, rgba(255,255,255,0.8926164215686274) 0%, rgba(255,255,255,0.6741290266106443) 45%, rgba(255,255,255,0) 100%);
  padding-bottom: 4%;
}
.fullWidthButton {
  width: 100%;
  text-align: left;
  padding-bottom: 1rem;
}
.editorButton {
  font-size: 25px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  @media(max-width: $break-point-sm) {
    height: 25px;
    width: 25px;
    line-height: 25px;
  }
}

.controlButton {
  border: 1px solid var(--darkerBgColor10);
  border-radius: 15px;
  font-weight: 700;
  font-size: 1.3rem;
  padding: 6px 22px;
  margin-top: 0;
  margin-bottom: 10px;
  @media(max-width: $break-point-sm) {
    margin-bottom: 10px;
  }
  &.closeButton {
    background: white;
    right: 0.3em;
    top: 8px;
  }

  &.saveButton {
    @include mixins.buttonColor(var(--secondaryNavBackground),var(--secondaryNavBackgroundTextColor));
    &[disabled] {
      @include mixins.buttonColor(var(--secondaryNavBackgroundTextColor), var(--secondaryNavBackground));

    }
  }

  &.deleteButton {
    @include mixins.buttonColor(white, $error-red);
    border: 1px solid $error-red;
  }

  &:hover, &:focus, &.deleteButton:hover, &.deleteButton:focus {
    background: darken(white, 10%);
  }
}
.reordering {
    cursor: move;
    padding-bottom: 20px !important;
}
.accordionItem{
  border-bottom: 1px solid #eeeeee;
  padding: 10px 0px 0px 22px;
  position: relative;
  display: block;
  width: 100%;
  .editorButton {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  &:hover .editItemButton {
    display: inline-block;

  }
  &:hover{
    background-color: darken(rgba(256,256,256,.75), 10%);
  }
}

.draggedItem {
  background: #ddddfc;
  border: 1px dashed #4040fa !important;
  margin:2px 0;
  width: 100%;
}

.expandCollapseButton {
  position: absolute;
  right: 25px;
  top: 30px;
  span {
    position: absolute;
    top: 20%; // Center the icon vertically
    left: 40%; // Center the icon horizontally
    transform: translate(-50%, -50%); // Pull the icon back to the center
    transition: transform .5s ease, opacity 0s ease .3s;
    opacity: 1; // Icons are initially visible
  }
  .rotate {
    transform: rotate(-90deg) translate(-50%, -50%); // Rotate the icon and pull it back to the center
    opacity: 0; // Icons are hidden when rotated
    transition: transform .5s ease, opacity 0s ease .3s;
  }

}
.messageContainer {
  position: relative;
  margin-right: 10px;
  vertical-align: middle;
  overflow-wrap: break-word;
  transition: transform .25s ease, opacity .5s ease;
  transition-delay: .25s;
  transform: translateX(-20%);
  opacity: 0;
}
.markdownEditor {
  background: white;
}


.editItemButton {
  display: none;
  position: absolute;
  padding-left: 10px;
  font-size: $terrace-body-font-size;
  line-height: 30px;
  @media(max-width: $break-point-sm) {
    display: inline-block;
    height: 25px;
    width: 25px;
    line-height: 25px;
  }
}

@keyframes accordion-entry-animation {
  from {
    opacity:0;
    transform:translateY(-10px)
  }
  to {
    opacity:1;
    transform:translateY(0px)
  }
}

.accordionItemContent {
  @include TerraceDescriptionStyles.terraceDescription;
  margin-bottom: 10px;
  overflow: hidden;
  width: 90%;
  color: $black;
  &.closed {
    max-height: 0;
  }
}
.accordionItemContent.open {
  animation: accordion-entry-animation 0.5s ease-in-out;
}

.accordionItemTitle {
    font-size: $terrace-subheading-font-size;
    font-weight: $terrace-subheading-font-weight-two;
    padding-right: 35px;
    cursor: pointer;
    color: $black;
    @media(max-width: $break-point-sm) {
      padding-right:45px;
      font-size: $terrace-body-font-size;
    }
}

.editor {
  > div:nth-of-type(2) {
    @include TerraceDescriptionStyles.terraceDescription;
  }
}

:global(.sb-with-background-image) .container .accordionList {
  @media (min-width: $break-point-sm) {
    overflow: hidden;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.75);
  }
}

.container {
  ul {
    list-style-type: disc;
    color: black;
    background: transparent;
    li {
      padding: 10px 10px 10px 10px;
      a {
        color: var(--blockBgTextColorNoBlocksUseSecondary);
        color: darkslategray;
      }
    }
  }
  .accordionList {
    padding: 0;
  }
}

.reorder {
  border: 1.5px solid $gray;
  border-radius: 5px;
}


.expandAllButtonContainer {
  padding-top: 4px;

  span {
    display: inline-block;
    padding: .75rem 1.25rem;
    border-radius: 10rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: .15rem;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-weight: $terrace-title-font-weight;
    background: white;
    box-shadow: 2px 2px 4px 2px #c0c0c0;

    @include mixins.leftToRightColorTransition(var(--secondaryNavBackground), var(--secondaryNavBackgroundTextColor));
  }
}






